// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://69ee786ab1f2499ba94f6713bc99c9c0@o336730.ingest.sentry.io/6258923',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // denyUrls: [/https:\/\/useblotter\.test:9104\/comments\/embed\/.*/],
  // debug: true,
  tunnel: '/api/errors/client',
})
