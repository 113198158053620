
// ------------------------------------------------------------------------------------ //
// Please note these are all PUBLICLY VISIBLE VALUES. Don't commit secrets to the repo! //
// ------------------------------------------------------------------------------------ //

type Environment = 'development' | 'production' | 'test'
let currentEnvironment: Environment = process.env.NODE_ENV || 'production'

type Config = {
  imageHostname: string,
  sentryDsn: string,
  // stripePublicKey: string,
  // Not working in www/server.js
  sslKeyPath?: string,
  sslCertPath?: string,
  mailchimpSubscribeDomain: string,
  apiBase?: string,
}

type EnvConfig = {
  development: Config,
  test?: Config,
  production: Config,
}

const config: EnvConfig = {
  development: {
    apiBase: 'https://useblotter.test:9104/api',
    sentryDsn: 'https://80319dd0594a42c1823f421ecea681d5@o336730.ingest.sentry.io/5339690',
    // stripePublicKey: 'pk_test_H7dYxdpxIhzoYydDACSqgVbL',
    imageHostname: 'https://d3br9f94aes2qf.cloudfront.net/',
    sslKeyPath: '/Users/matt/code/hiblotter/ssl/localcert/api.hiblotter.test-key.pem',
    sslCertPath: '/Users/matt/code/hiblotter/ssl/localcert/api.hiblotter.test.pem',
    mailchimpSubscribeDomain: 'hiblotter.us20.list-manage.com',
  },
  test: {
    apiBase: 'https://useblotter.com/api',
    sentryDsn: 'https://80319dd0594a42c1823f421ecea681d5@o336730.ingest.sentry.io/5339690',
    imageHostname: 'https://d3br9f94aes2qf.cloudfront.net/',
    sslKeyPath: '/Users/matt/code/hiblotter/ssl/localcert/api.hiblotter.test-key.pem',
    sslCertPath: '/Users/matt/code/hiblotter/ssl/localcert/api.hiblotter.test.pem',
    mailchimpSubscribeDomain: '',
  },
  production: {
    apiBase: 'https://useblotter.com/api',
    sentryDsn: 'https://80319dd0594a42c1823f421ecea681d5@o336730.ingest.sentry.io/5339690',
    // stripePublicKey: 'pk_live_rS9G6W6KkZjNVdPVoUoQUDlZ',
    imageHostname: 'https://d3br9f94aes2qf.cloudfront.net/',
    mailchimpSubscribeDomain: 'hiblotter.us20.list-manage.com',
  }
}

export const setConfigEnv = (environment: Environment) => {
  // console.log('SET CONFIG ENV', environment)
  currentEnvironment = environment
}

export const getConfig = (): Config | undefined => {
  // console.log('GET CONFIG', currentEnvironment)
  if (!currentEnvironment) {
    const environment: Environment | undefined = process?.env?.ENV as Environment || process?.env?.NODE_ENV as Environment
    if (environment) {
      setConfigEnv(environment)
    }
  }
  if (!currentEnvironment) {
    throw new Error('Environment not set')
  }
  return config[currentEnvironment]
}

export default config
