import { getConfig, setConfigEnv } from '../config/envVars'
import App from 'next/app'
import Head from 'next/head'
import '../public/static/styles/empty.css'
import PlausibleProvider from 'next-plausible'
import { SessionProvider } from 'next-auth/react'
import { SWRConfig } from 'swr'
import { fetcher } from 'utilities/api'

import 'public/static/styles/index.css'
import 'public/static/styles/fonts.css'
import '../styles/globals.css'

const env = process?.env?.ENV || process?.env?.NODE_ENV
setConfigEnv(env)
const config = getConfig()

class MyApp extends App {
  componentDidMount () {
    // if (!_.isUndefined(window)) {
    //   initAmplitude()
    // }
    // initHelloNext()
    window.hiblotter = {}
    // Sentry.init({
    //   dsn: config.sentryDsn,
    //   environment: env,
    // })

    window.hiblotter.settings = {
      API_BASE: config.apiBase,
      // STRIPE_PUBLIC_KEY: config.stripePublicKey,
    }
  }

  render () {
    const { Component, pageProps: pagePropsAll } = this.props
    const { session, ...pageProps } = pagePropsAll
    return <>
      {/* Leave domain as hiblotter for Plausible, it still works */}
      <PlausibleProvider domain='hiblotter.com'>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        {/* <link rel="stylesheet" href="https://rsms.me/inter/inter.css" /> */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest"></link>
      </Head>
      {/* <Provider store={ reduxStore }> */}
      {/* <ThemeProvider theme={materialTheme}> */}
      <SessionProvider session={session}>
        <SWRConfig value={{
          fetcher,
        }}>
          <Component {...pageProps} />
        </SWRConfig>
      </SessionProvider>
      {/* </ThemeProvider> */}
      {/* </Provider> */}
      </PlausibleProvider>
    </>
  }
}

export default MyApp
