import { getConfig } from '../config/envVars'
const config = getConfig()

const API_BASE = config?.apiBase
// console.log({ API_BASE })

declare global {
  interface Window { blotter: any; }
}

import axios, { AxiosResponse } from 'axios'
import { signOut } from 'next-auth/react'
// import useSWR from 'swr'
axios.defaults.withCredentials = true

interface GetOptions {
  params: { [key: string]: string | number }
}

const checkAuth = (result: AxiosResponse) => {
  // console.log('CHECK AUTH', { result })
  if (result.status === 401 && result.data === 'Unauthorized - Reauthenticate') {
    // console.log('SIGN OUT FOR RE-AUTH')
    signOut()
  }
}

export const get = async (path: string, options?: GetOptions) => {
  // console.log('GET', { API_BASE, path })
  const getResult = await axios({
    method: 'get',
    url: `${API_BASE}${path}`,
    params: options?.params || undefined,
  })
  checkAuth(getResult)
  return getResult
}

export const post = async (path: string, payload?: Object, options?: Object) => {
  console.log('POST', { API_BASE, path })
  const postResult = await axios({
    ...options,
    method: 'post',
    url: `${API_BASE}${path}`,
    data: payload,
  }).catch((error) => {
    checkAuth(error.response)
  })
  if (!postResult) {
    console.error(`Post Response Error: ${path}`)
    return postResult
    // throw new Error(`Post Response Error: ${path}`)
  }
  checkAuth(postResult)
  return postResult
}

export const patch = async (path: string, payload?: Object) => {
  const patchResult = await axios({
    method: 'patch',
    url: `${API_BASE}${path}`,
    data: payload,
  })
  checkAuth(patchResult)
  return patchResult
}

// For use with SWR
export const fetcher = (urlPath: string) => axios.get(`${API_BASE}${urlPath}`).then(res => res.data)

export const HttpStatusCodes = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
}

export const Response = {
  success: (data: any = null) => ({
    status: HttpStatusCodes.SUCCESS,
    data,
  }),
  created: (data: any = null) => ({
    status: HttpStatusCodes.CREATED,
    data,
  }),
  badRequest: (data: any = null) => ({
    status: HttpStatusCodes.BAD_REQUEST,
    data,
  }),
  unauthorized: (data: any = null) => ({
    status: HttpStatusCodes.UNAUTHORIZED,
    data,
  }),
  forbidden: (data: any = null) => ({
    status: HttpStatusCodes.FORBIDDEN,
    data,
  }),
  notFound: (data: any = null) => ({
    status: HttpStatusCodes.NOT_FOUND,
    data,
  }),
  internalServerError: (data: any = null) => ({
    status: HttpStatusCodes.INTERNAL_SERVER_ERROR,
    data,
  }),
}

export const isSuccessful = (response: AxiosResponse) => {
  return Boolean(response.status < 300)
}

// only used for Stripe calls (can be switched to use regular post)
export async function fetchPostJSON(url: string, data?: Record<string, unknown>) {
  try {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
    })
    return await response.json() // parses JSON response into native JavaScript objects
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message)
    }
    throw err
  }
}



// export const useApi = (label: string, path: string) => {
//   const { data, error } = useSWR(path)
//   console.log('useApi', path, label, { data })

//   return {
//     csrfToken,
//     isCsrfTokenLoading: !error && !csrfToken,
//     isCsrfTokenError: error,
//   }
// }
